import React from 'react'
import Layout from '../components/Layout/Layout'
import PincHelmet from '../components/Helmet/PincHelmet'
import FilterBreak from '../components/FilterBreak/FilterBreak'
import pincailogo from '../assets/images/pincai-text-logo-white.svg'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router'
import * as styles from '../styles/thankyou.module.scss'

const ThankYou = (props) => {
  // const {} = props
  const queryString = new URLSearchParams(useLocation().search)
  const successMessage = queryString.get('success')

  // message to display if no query string is present /thankyou

  const DefaultSuccessMessage = () => (
    <>
      <h2 className={styles.h2}>Thank you for contacting us.</h2>
      <p className={styles.text}>One of our PINC AI professionals will be in touch shortly.</p>
      <Link to="/#solutions" className={styles.button}>
        <span>CHECK OUT PINC AI SOLUTIONS</span>
      </Link>
    </>
  )

  // message to display if user submits form on top 100 page /thankyou?success=top100

  const Top100SuccessMessage = () => (
    <>
      <h2 className={styles.h2}>Thank you for contacting us.</h2>
      <p className={styles.text}>Someone from the 100 Top Hospitals program will be in touch shortly.</p>
      <Link to="/#solutions" className={styles.button}>
        <span>CHECK OUT PINC AI SOLUTIONS</span>
      </Link>
    </>
  )

  return (
    <Layout>
      <PincHelmet title={'Thank You | PINC-AI'} />
      <main className={styles.container}>
        <h1 className={styles.h1}>
          <span>
            <img src={pincailogo} alt="Pinc AI Logo" />
          </span>
          <span>Thank You</span>
        </h1>
        <FilterBreak />
        <div className={styles.sectionWrapper}>
          <section className={styles.section}>
            {successMessage === '100top' ? <Top100SuccessMessage /> : <DefaultSuccessMessage />}
          </section>
        </div>
      </main>
    </Layout>
  )
}

export default ThankYou
